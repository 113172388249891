
        import content from './template.html';
        import styles from './styles.css';

        const template = document.createElement('template');
        const stylesUrl = URL.createObjectURL(new Blob([styles], { type: 'text/css' }));

        template.innerHTML = `
            <link rel="stylesheet" href="${stylesUrl}" />

            ${content}
        `;

        export default template;
    